<template>

    <div class="amenagement">
    <barre-navigation></barre-navigation>

        <div class="specialiteAmenagement">
            <!-- <div class="lignenav"></div> -->
            <div class="blocAmenagement">
                <div class="centrerAmenagement">
                    <div class="titreAmenagement">
                        <h1> Aménagement de Combles </h1>
                        <div class="ligneAmenagement"></div>
                    </div>
                    <div class="descriptionAmenagement">
                        <p> Une bonne isolation permet d’avoir un bâtiment moins <span>énergivore</span>. Pour information, une mauvaise isolation des combles entraîne jusqu’à 30% de déperdition de chaleur.
                        Un comble est <span> aménageable </span> si la hauteur sous la charpente est supérieure à 1,80 mètres, si la configuration de la charpente permet d'accéder à l'espace, si la pente du toit est supérieure à 30 % et si la largeur sous la tuile de faîtage est de plus de 60 centimètres.
                        <br> Nous réalisons l’isolation par l’intérieur sous les <span> rampants </span> et les <span> pignons </span> ainsi que l’habillage des <span> fenêtres de toit </span>.
                        </p>
                    </div>
                </div>

                <div class="imgAmenagement">
                    <img alt="image-amenagement-comble" src="../assets/amenagement.png">
                </div>

                <div class="sliderAmenagement">
                    <div class="slider1">
                    <cartes-avant-apres-pages-details imgav="chambreav.png" imgap="chambreap.png"></cartes-avant-apres-pages-details>
                </div>
                <div class="slider2">
                    <cartes-avant-apres-pages-details imgav="amenagementav.png" imgap="amenagementap.png"></cartes-avant-apres-pages-details>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>

    import BarreNavigation from '../components/BarreNavigation.vue'
    import CartesAvantApresPagesDetails from '../componentschild/CartesAvantApresPagesDetails.vue'

    export default {
        name: 'Amenagement',
        components: {
        BarreNavigation, CartesAvantApresPagesDetails
        }
    }

</script>

<style scoped>

    .specialiteAmenagement {

        padding-right: 14rem;
        padding-left: 14rem;

    }

    .blocAmenagement {

        padding: var(--padding-haut-bas-section);

    }

    .blocAmenagement h1 {

        font-size: 36px;
        font-weight: bold;
        color: #333F50;
        padding-bottom: 40px;

    }

    .blocAmenagement p {

        font-size: 16px;
        color: #333F50;

    }

    .centrerAmenagement {

        display: flex;

    }

    .ligneAmenagement {

        background-color: #7C928F;
        height: 10px;
        width: 400px;

    }

    .descriptionAmenagement {

        margin-left: 50px;
        font-size: 20px;
        

    }

    .descriptionAmenagement p {

        line-height: var(--line-height-pagesdetails);

    }

    .descriptionAmenagement span {

        font-weight: bold;

    }

    .imgAmenagement {

        display: flex;
        justify-content: space-around;
        padding-top: 50px;

    }

    .imgAmenagement img {

        height: 60%;
        width: 60%;

    }

    .slider1, .slider2 {

        display: flex;
        justify-content: center;

    }

    .slider2 {

        margin-top: 80px;

    }

    @media screen and (max-width: 1500px) {

    .imgAmenagement {

        padding-top: 80px;

    }

    }

    @media screen and (max-width: 1400px) {

    .descriptionAmenagement p {

        line-height: var(--line-height-medium);

    }

    }


    @media screen and (max-width: 1300px) {

    .ligneAmenagement {
        
        width: 300px;

    }

    .imgAmenagement {

        padding-top: 60px;

    }

    .imgAmenagement img {

        height: 80%;
        width: 80%;

    }

    }

    @media screen and (max-width: 1110px) {

    .centrerAmenagement {

        flex-direction: column;
        align-items: center;

    }

    .titreAmenagement h1 {

        text-align: center;

    }

    .ligneAmenagement {

        width: 500px;
        height: 8px;

    }

    .descriptionAmenagement {

        padding-top: 40px;
        display: flex;
        justify-content: center;
        margin-left: 0px;

    }

    .descriptionAmenagement p {

        width: 500px;
        text-align: justify;

    }

    .imgAmenagement {

        padding-top: 40px;

    }

    .imgAmenagement img {

        height: 78%;
        width: 78%;

    }

    .slider2 {

        margin-top: 30px;

    }

    }

    @media screen and (max-width: 1000px) {

        .imgAmenagement img {

            padding-top: 60px;
            
        } 

    }

    @media screen and (max-width: 850px) {

        .imgAmenagement img {

            height: 450px;
            width: 600px;
            padding-top: 30px;

        } 

    }

    @media screen and (max-width: 750px) {

        .imgAmenagement img {

            height: 345px;
            width: 460px;

        } 

        .slider2 {

            margin-top: 10px;

        }

    }

    @media screen and (min-width: 670px) and (max-width: 870px) {

        .blocAmenagement {

            padding-top: 60px;

        }

    }

    @media screen and (max-width: 550px) {

        .titreAmenagement h1 {

            font-size: 28px;
            text-align: center;
            padding-bottom: 30px;

        }

        .ligneAmenagement {

            width: 300px;
            height: 6px;

        }

        .descriptionAmenagement p {

            width: 300px;
            font-size: 14px;

        }

        .imgAmenagement img {

            height: 240px;
            width: 320px;

        }

        .slider1 {

            height: 320px;

        }

        .slider2 {

            height: 320px;
            margin-top: 0px;

        }

    }

</style>